<template>
  <div class="table-purchases">
    <div class="mb-2 d-flex flex-wrap justify-content-end align-items-start">
      <b-button
          v-b-modal.add_purchase
          variant="outline-primary"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-50"
        />
        <span class="align-middle">Создать закупку</span>
      </b-button>
    </div>
    <b-table
        id="table_load_products"
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getListPurchases"
        :current-page="currentPage"
        :per-page="10">
      <template #cell(name)="data">
        <b-link :to="`/procurement/${data.item.id}`">
          <div class="d-flex" style="width: 300px;">
            <div> {{ `#${data.item.id} ${data.item.name}` }}</div>
          </div>
        </b-link>
      </template>
      <template #cell(money)="data">
        <div style="width: 95px">
          <money-format :cost="data.item.purxhasePrice || 0"/>
        </div>
      </template>
      <template #cell(amount)="data">
        <div style="width: 81px">
          {{ data.item.products.reduce((acc, prod) => acc + prod.amount, 0) }}
        </div>
      </template>
      <template #cell(warehouse)="data">
        <div style="width: 100px">
          {{ data.item.warehouse || 'Не выбран' }}
        </div>
      </template>
      <template #cell(date)="data">
        <div style="width: 120px">
          {{ getDate(data.item.date_purchase) }}
        </div>
      </template>
      <template #cell(status)="data">
        <b-dropdown
            variant="link"
            toggle-class="button-set-status"
            dropright
        >
          <template v-slot:button-content>
            <b-badge :style="{'background-color': statusColor[data.item.status]}"
            > {{ data.item.status }}
            </b-badge>
          </template>
          <b-dropdown-item
              v-for="status in statuses"
              :key="status"
              @click="function() { setStatus({status, id: data.item.id}) }">
            <b-badge :style="{'background-color': statusColor[status]}"
            >
              {{ status }}
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(contractor)="data">
        <div style="width: 120px">
          {{ data.item.contractor }}
        </div>
      </template>
      <template #cell(menu)="data">
        <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
        >
          <template v-slot:button-content>
            <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item
              @click="function() { editPurchase(data.item) }"
          >
            <feather-icon
                icon="Edit2Icon"
                class="mr-50"
            />
            <span>Править</span>
          </b-dropdown-item>
          <b-dropdown-item
              @click="function() { delPurchase(data.item.id) }">
            <feather-icon
                icon="TrashIcon"
                class="mr-50"
            />
            <span>Удалить</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-modal
        id="add_purchase"
        hide-header
        hide-footer
        size="lg"
        centered
        v-model="showModal"
        @hide="clearCreatePurchase"
    >
      <purchase-create
          ref="creator"
          :purchase="selectEditPurchase"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BBadge,
  BTable,
  BNavItem,
  BLink,
  BCol,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BCardText,
  BSpinner,
  BModal,
  BFormInput,
  BPopover,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  VBPopover,
} from 'bootstrap-vue';


import OrderStatus from './OrderStatus';
import cellShowDetail from './cellShowDetail';
import TablePurchaseProducts from './TablePurchaseProducts';
import ProductCellSimple from '../ProductCellSimple';
import PopUpSMV from './PopUpSMV';
import PurchaseCreate from './PurchaseCreate';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TablePurchase",
  directives: {
    'b-popover': VBPopover
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      currentStatus: '',
      selectEditPurchase: null,
      currentPage: 1,
      showModal: false,
      statusColor: {
        'План': '#34C3E3',
        'В производстве': '#C369DA',
        'В пути': '#E19D4E',
        'Приемка': '#5293A1',
        'Принято': '#4DCEA7',
        'Возврат': '#F38374',
      },
      fields: [
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'money',
          label: 'Объем Руб',
        },
        {
          key: 'amount',
          label: 'Объем шт',
        },
        {
          key: 'status',
          label: 'Статус',
        },
        {
          key: 'contractor',
          label: 'Поставщик',
        },
        {
          key: 'warehouse',
          label: 'Склад',
        },
        {
          key: 'date',
          label: 'Дата закупки',
        },
        {
          key: 'menu',
          label: '',
        },
      ],
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  methods: {
    checkProductsToOperation() {
      console.log('this.subSortToOperation.length', this.subSortToOperation.length);
      if (this.subSortToOperation.length) this.showModal = true;
    },

    clearCreatePurchase() {
      this.selectEditPurchase = null
      this.clearSubSortToOperation();
    },

    editPurchase(id) {
      this.selectEditPurchase = id;
      this.showModal = true
    },
    getDate(date) {
      if (date) {
        date = new Date(date);
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },
    ...mapActions('purchase', ["loadListPurchase", "delPurchase", "setStatus"]),
    ...mapActions('unitReport', ["clearSubSortToOperation"]),
  },

  computed: {
    ...mapGetters('purchase', ['getListPurchases']),
    ...mapState('purchase', ['statuses']),
    ...mapState('unitReport', ['subSortToOperation']),
  },

  watch: {},

  components: {
    ProductCellSimple,
    BCard,
    BNavItem,
    BFormSelectOption,
    BPopover,
    BDropdown,
    BDropdownItem,
    PurchaseCreate,
    TablePurchaseProducts,
    BTabs,
    BTab,
    BCardText,
    BLink,
    BSpinner,
    cellShowDetail,
    BButton,
    BBadge,
    BPagination,
    BFormDatepicker,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    OrderStatus,
    PopUpSMV
  },
  async mounted() {
    this.checkProductsToOperation()
  },
}
</script>

<style>
.button-edit-purchase {
  outline: none;
  border: none;
  background: none;
}

.button-set-status {
  padding: 0;
}

.button-set-status::after {
  content: none;
}


</style>