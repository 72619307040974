<template>
  <b-card>
    <TablePurchase/>
  </b-card>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
  VBModal,
} from 'bootstrap-vue';

import TablePurchase from "@/components/procurement/TablePurchase";
import {mapActions, mapState} from 'vuex';

export default {
  name: "Procurement",

  data() {
    return {
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormInput,
    BFormGroup,
    BFormSelect,
    TablePurchase
  },

  directives: {
    'b-modal': VBModal
  },

  methods: {
    ...mapActions('products', ["reloadNomenclaturesAll"]),
    ...mapActions('orders', ["clear"]),
    ...mapActions('purchase', ["loadListPurchase", "loadCatalogs"]),
    ...mapActions('user', ["loadCountries"]),
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },

  computed: {
    ...mapState('products', ['listAllCategories', "listAllBrands", "productsWidthWB", "selectedProductsId"]),
  },

  mounted() {
    this.loadListPurchase();
    this.loadCatalogs();
    this.loadCountries();
  },

  unmounted() {
    this.clear();
  }
}
</script>

<style scoped>

</style>